import ProjectModelAttributeItem from "./ProjectModelAttributeItem";

export default class ProjectModelTableItem {
    modelId = -1;
    modelExpand = false;
    modelStatus = 1;
    modelType = "class";
    modelParentId = 0;
    modelSortId = 0;
    modelObject = "single";
    modelCode = "top";
    modelName = "顶层";
    modelParentCode = "";
    modelParentName = "";
    attributeExpand = false;
    attributes = "";
    attributeJson: ProjectModelAttributeItem[] = [];
    modelRemark = "";
    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";
    childrenCount = 0;
    children: ProjectModelTableItem[] = [];
}
