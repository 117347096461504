import { Option } from "@/components/select/select";

//项目提交数据
export class ProjectEditData {
    parentCode = "";
    projectClass = "";
    action = "";
    data: any = {};
}
//模型类型选项
export const ModelTypeOptions: any = [
    { label: "目录", value: "class" },
    { label: "设备", value: "device" },
    { label: "部件", value: "part" },
];
//模型对象选项
export const ModelObjectOptions: any = [
    { label: "单例", value: "single" },
    { label: "多例", value: "multiple" },
];
//属性类型选项
export const AttributeTypeOptions: Array<Option> = [
    { label: "字符串", value: "str" },
    { label: "数值", value: "int" },
    { label: "时间", value: "time" },
];
//作用域选项
export const AttributeScopeOptions: Array<Option> = [
    { label: "继承", value: "in" },
    { label: "私有", value: "pre" },
];
